export function venuePath(venue: { slug_id: string; city: string; name: string }) {
  const path = [...venue.city.split(" "), ...venue.name.split(" ")].map((s) => s.toLowerCase()).join("-");
  return `/sala/${path}-${venue.slug_id}`;
}

export function venuesPath() {
  return `/salas`;
}

export function playPath(play: { slug: string; slug_id: string }) {
  return `/play/${play.slug}-${play.slug_id}`;
}
